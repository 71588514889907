import React from 'react';
import { Outlet } from 'react-router-dom';
import { OnboardingProvider } from '../Context/OnboardingContext';

const OnboardingLayout = () => {
  return (
    <OnboardingProvider>
      <div className={'flex flex-col flex-1 h-full'}>
        <div className={'m-auto w-full'}>
          <Outlet />
        </div>
      </div>
    </OnboardingProvider>
  );
};

export default OnboardingLayout;
