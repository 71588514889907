import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnboardingProvider } from '../Context/OnboardingContext';
import { PaymentService } from '@/services/PaymentService';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { useTranslation } from 'react-i18next';

export const CheckoutForm = () => {
  const elements = useElements();
  const navigate = useNavigate();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { quote } = useOnboardingProvider();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    if (!stripe || !elements) {
      throw new Error('Stripe or Elements not loaded');
    }

    let checkPaymentMethod = e.nativeEvent.submitter.name === 'payment';
    if (checkPaymentMethod) {
      const { error } = (await stripe.confirmSetup({
        elements,
        clientSecret: quote.client_secret,
        confirmParams: {
          return_url:
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port !== '80' ? ':' + window.location.port : '') +
            '/thanks',
        },
        redirect: 'always',
      })) as any;

      if (error) {
        setIsLoading(false);
        setError(error.message);
        return;
      }
    } else {
      const result = (await PaymentService.acceptQuote(quote.id)) as any;
      if (result.accepted === false) {
        setIsLoading(false);
        setError(t('onboarding.checkout.subscription_could_not_be_created'));
        return;
      }

      navigate('/thanks');
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="space-y-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>{t('onboarding.checkout.payment_method')} </CardTitle>
        </CardHeader>
        <CardContent>
          <PaymentElement className="mb-2" />
          {error !== '' && <span className="text-error">{error}</span>}
        </CardContent>
      </Card>
      <div className="pt-4">
        <p className="text-xs text-gray-600 mb-2 ml-1">
          {t('onboarding.checkout.terms_1')}{' '}
          <a href="https://workplan.digital/agb" target="_blank" className="link">
            {t('onboarding.checkout.agb')}
          </a>{' '}
          {t('onboarding.checkout.terms_2')}{' '}
          <a href="https://workplan.digital/datenschutz" target="_blank" className="link">
            {t('onboarding.checkout.data_privacy')}
          </a>{' '}
          {t('onboarding.checkout.terms_3')}
        </p>
        {quote.computed && quote.computed.upfront.amount_total === 0 && (
          <Button
            variant={'ghost'}
            className="mr-2"
            disabled={isLoading}
            name="no_payment"
            type="submit"
          >
            {t('onboarding.checkout.subscribe_without_payment')}
          </Button>
        )}
        <Button variant={'default'} disabled={isLoading} name="payment" type="submit">
          {t('onboarding.checkout.subscribe')}
        </Button>
      </div>
    </form>
  );
};
