import axios from 'axios';
import React from 'react';
import { store } from '@/global/store';
import { loginUser, logoutUser, updateToken } from '@/global/authSlice';
import {
  selectAffiliate,
  setAffiliates,
  clearState as clearAffiliateState,
} from '@/global/affiliateSlice';
import { clearState as clearUserState, setUserData } from '@/global/userSlice';
import { toast, TypeOptions } from 'react-toastify';
import { t, exists } from 'i18next';

const BASE_URL = import.meta.env.VITE_API_URL;

const ToastMessage = ({ title, text }) => (
  <div>
    <h2 className={'font-bold'}>{title}</h2>
    <p>{text}</p>
  </div>
);

export const createNotification = (title: string, text: string, type: TypeOptions) => {
  toast.info(<ToastMessage title={title} text={text} />, {
    toastId: 'toast-message',
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    type: type,
  });
};

export function createErrorNotification(err: any) {
  if (
    err?.response?.data?.message !== undefined &&
    exists('api.error.' + err.response.data.message + '.title')
  ) {
    createNotification(
      t('api.error.' + err.response.data.message + '.title'),
      t('api.error.' + err.response.data.message + '.body'),
      'error',
    );
  } else {
    createNotification(
      t('api.error.UNEXPECTED_ERROR.title'),
      t('api.error.UNEXPECTED_ERROR.body'),
      'error',
    );
  }
}

export function login(username: string, password: string): Promise<any> {
  return new Promise((resolve, reject) => {
    return fetchData({
      url: '/api/login',
      publicRequest: true,
      responseType: 'json',
      data: {
        username,
        password,
      },
    })
      .then((result: any) => {
        store.dispatch(
          loginUser({
            token: result.token,
            refreshToken: result.refresh_token,
          }),
        );

        let defaultAffiliateId = null;
        if (result.data.default_affiliate !== null) {
          defaultAffiliateId = result.data.default_affiliate;
        }

        store.dispatch(setAffiliates(result.data.affiliates));
        if (defaultAffiliateId !== null) {
          store.dispatch(selectAffiliate(defaultAffiliateId));
        }

        store.dispatch(
          setUserData({
            id: result.data.id,
            firstname: result.data.first_name,
            lastname: result.data.last_name,
            email: username,
            needs_onboarding: result.data.needs_onboarding,
          }),
        );

        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function logout() {
  const refresh_token = store.getState().auth.refresh_token;
  return new Promise((resolve, reject) => {
    fetchData({
      url: '/api/token/invalidate',
      data: {
        refresh_token,
      },
    })
      .then((result) => {
        store.dispatch(logoutUser());
        store.dispatch(clearUserState());
        store.dispatch(clearAffiliateState());
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function apiRequest(options: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await fetchData(options);
      resolve(result);
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          await refreshToken();
          const result = await fetchData(options);
          resolve(result);
        } else {
          reject(error);
        }
      } catch (error: any) {
        store.dispatch(logoutUser());
        store.dispatch(clearUserState());
        store.dispatch(clearAffiliateState());
        if (error?.response?.status === 401) {
          reject({ response: { data: { message: 'FORCE_LOGOUT' } } });
        } else {
          reject(error);
        }
      }
    }
  });
}

/*
options: {
    url: '/api/user',
    timeout: 5000,
    method: ''
    data: {
        firstname: 'max',
        lastname: 'muster
    }
}
 */
export function fetchData(options: any) {
  const defaultOptions = {
    timeout: 30000,
    method: 'POST',
    publicRequest: false,
    responseType: 'json',
    fullResponse: false,
  };

  const requestOptions = { ...defaultOptions, ...options };
  return new Promise((resolve, reject) => {
    const headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const token = store.getState().auth.token;
    if (token && requestOptions.publicRequest === false) {
      headers.Authorization = 'Bearer ' + token;
    }

    axios({
      url: BASE_URL + requestOptions.url,
      timeout: requestOptions.timeout,
      method: requestOptions.method,
      headers,
      data: requestOptions.data,
      responseType: requestOptions.responseType,
    })
      .then((result) => {
        let data = result.data;
        if (requestOptions.fullResponse) {
          data = result;
        }

        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function refreshToken() {
  return new Promise((resolve, reject) => {
    const token = store.getState().auth.refresh_token;
    return axios({
      url: BASE_URL + '/api/token/refresh',
      timeout: 5000,
      method: 'POST',
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: { refresh_token: token },
    })
      .then((result) => {
        const data = result.data;
        if (result.status === 200) {
          store.dispatch(updateToken(data.token));

          let defaultAffiliateId = null;
          if (data.default_affiliate !== null) {
            defaultAffiliateId = data.data.default_affiliate;
          }

          store.dispatch(setAffiliates(data.data.affiliates));
          if (defaultAffiliateId !== null) {
            store.dispatch(selectAffiliate(defaultAffiliateId));
          }

          store.dispatch(
            setUserData({
              id: data.data.id,
              firstname: data.data.first_name,
              lastname: data.data.last_name,
              needs_onboarding: data.data.needs_onboarding,
            }),
          );

          resolve(data);
        } else {
          reject(data.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
