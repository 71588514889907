import { createSlice } from '@reduxjs/toolkit';

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState: {
    affiliates: [],
    selectedAffiliate: {
      id: null,
      title: null,
      isAdmin: false,
      isConfigured: false,
      hasLicense: false,
      addons: {
        cards: false,
        projects: false,
      },
    },
  },
  reducers: {
    setAffiliates: (state, action) => {
      state.affiliates = action.payload;
    },
    selectAffiliate: (state: any, action) => {
      state.selectedAffiliate = {
        id: state.affiliates[action.payload].id,
        title: state.affiliates[action.payload].title,
        isAdmin: state.affiliates[action.payload].is_admin,
        isConfigured: state.affiliates[action.payload].is_configured,
        hasLicense: state.affiliates[action.payload].has_license,
        addons: state.affiliates[action.payload].addons,
      };
    },
    clearState(state) {
      state.affiliates = [];
      state.selectedAffiliate = {
        id: null,
        title: null,
        isAdmin: false,
        isConfigured: false,
        hasLicense: false,
        addons: {
          projects: false,
          cards: false,
        },
      };
    },
  },
});

export const { setAffiliates, selectAffiliate, clearState } = affiliateSlice.actions;

export default affiliateSlice.reducer;
