import React from 'react';
import { useState } from 'react';

export interface LoadingComponentProps {
  noText?: boolean;
  size?: string;
  paddingTop?: boolean;
}

export const LoadingComponent = ({ noText, size = 'md', paddingTop }: LoadingComponentProps) => {
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  setTimeout(() => {
    if (noText === false) {
      setMessage('Diese Aktion dauert länger als gewöhnlich. Bitte laden Sie die Seite neu.');
    }
  }, 3000);

  setTimeout(() => {
    setShowLoader(true);
  }, 600);

  if (!showLoader) return <></>;

  return (
    <div
      className={
        'flex justify-center items-center h-full w-full flex-col ' + (paddingTop ? 'pt-4' : '')
      }
    >
      <span className={'loading loading-spinner loading-' + size}></span>
      <span className={'mt-2 text-gray-500'}>{message ? message : ''}</span>
    </div>
  );
};
