import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { ShiftService } from '@/services/ShiftService';

export const CreateShiftModal = ({ isModalOpen, setIsModalOpen, reload, defaultDay }) => {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const user = useSelector((state: any) => state.user);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const initialShift = {
    user_id: user.id,
    shift_date: defaultDay,
    shift_start: '09:00',
    shift_end: '17:00',
    pause: 0,
    note: '',
    project_id: '',
  };

  const [shift, setShift] = useState({ ...initialShift });
  const setShiftData = (evt) => {
    if (evt.target.name === 'user_id') {
      let user: any =
        users.filter((user: any) => {
          return user.id === parseInt(evt.target.value);
        })[0] ?? [];
      setProjects(user.projects);
      setShift({
        ...shift,
        project_id: '',
        [evt.target.name]: evt.target.value,
      });
    } else {
      setShift({ ...shift, [evt.target.name]: evt.target.value });
    }
  };

  const createShift = () => {
    setIsSaving(true);

    ShiftService.createShift({
      affiliate_id: selectedAffiliate.id,
      user_id: shift.user_id,
      note: shift.note,
      pause: parseInt(shift.pause),
      project_id: parseInt(shift.project_id) ?? null,
      start: DateTime.fromSQL(shift.shift_date + ' ' + shift.shift_start).toISO({
        suppressMilliseconds: true,
      }) as string,
      end: DateTime.fromSQL(shift.shift_date + ' ' + shift.shift_end).toISO({
        suppressMilliseconds: true,
      }) as string,
    })
      .then(async () => {
        createNotification(
          t('time_tracking.notifications.add_time_header'),
          t('time_tracking.notifications.add_time_message', {
            date: DateTime.fromSQL(shift.shift_date).toFormat('dd.LL.'),
          }),
          'success',
        );
        setShift({ ...initialShift });
        reload(null, null);
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (users.length === 0) {
      ShiftService.getModal(selectedAffiliate.id)
        .then((res: any) => {
          setUsers(res);
          let user: any =
            res.filter((user: any) => {
              return user.id === parseInt(user.id);
            })[0] ?? [];
          setProjects(user?.projects ? Object.values(user.projects) : []);
          setShift({ ...shift, user_id: user.id });
        })
        .catch((err) => {
          createErrorNotification(err);
        });
    }

    setShift({ ...shift, shift_date: defaultDay });
  }, [defaultDay]);

  return (
    <Modal
      headline={t('time_tracking.working_time')}
      onSafe={createShift}
      modalName="add_time"
      isOpen={isModalOpen}
      isLoading={isSaving}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('time_tracking.employee')}</span>
        </label>
        <select
          name={'user_id'}
          value={shift.user_id}
          onChange={setShiftData}
          className="select select-bordered w-full max-w"
        >
          <option value={''}>{t('general.please_select')}</option>
          {users.map((user: any, key: number) => {
            return (
              <option key={key} value={user.id}>
                {user.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('time_tracking.date')}</span>
        </label>
        <input
          type="date"
          name={'shift_date'}
          value={shift.shift_date}
          onChange={setShiftData}
          placeholder=""
          className="input input-bordered w-full"
        />
      </div>
      <div className={'grid grid-cols-2 space-x-2'}>
        <div className="form-control">
          <label className="label">
            <span className="label-text">{t('time_tracking.start')}</span>
          </label>
          <input
            type="time"
            name={'shift_start'}
            value={shift.shift_start}
            onChange={setShiftData}
            placeholder=""
            className="input input-bordered w-full"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">{t('time_tracking.end')}</span>
          </label>
          <input
            type="time"
            name={'shift_end'}
            value={shift.shift_end}
            onChange={setShiftData}
            className="input input-bordered w-full"
          />
        </div>
      </div>
      <div className={'grid grid-cols-2 space-x-2'}>
        <div className="form-control">
          <label className="label">
            <span className="label-text">{t('time_tracking.pause')}</span>
          </label>
          <input
            type="number"
            name={'pause'}
            value={shift.pause}
            onChange={setShiftData}
            placeholder=""
            className="input input-bordered w-full"
          />
        </div>
      </div>
      <div className={'form-control w-full ' + (projects.length === 0 ? 'hidden' : '')}>
        <label className="label">
          <span className="label-text">{t('general.project')}</span>
        </label>
        <select
          name={'project_id'}
          value={shift.project_id}
          onChange={setShiftData}
          className="select select-bordered w-full max-w"
        >
          <option value={''}>{t('general.please_select')}</option>
          {projects.map((project: any, key: number) => {
            return (
              <option key={key} value={project.id}>
                {project.title}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('general.note')}</span>
        </label>
        <textarea
          name={'note'}
          onChange={setShiftData}
          className="textarea textarea-bordered h-10"
        />
      </div>
    </Modal>
  );
};
