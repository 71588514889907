import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BsInfoCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import LicenseSelection from '@/components/Commerce/LicenseSelection';
import { PaymentService } from '@/services/PaymentService';
import { createErrorNotification } from '@/helper/ApiHelper';

export default function PaymentSettingsPage() {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const { t } = useTranslation();

  const [isRedirectingToPortal, setIsRedirectingToPortal] = useState(false);
  const [upcomingSubscription, setUpcomingSubscription] = useState(null);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState({});

  const redirectToPortal = async () => {
    setIsRedirectingToPortal(true);
    PaymentService.redirectToPortal(selectedAffiliate.id)
      .then((data: any) => {
        window.location.replace(data.portal_url);
      })
      .catch((err) => {
        setIsRedirectingToPortal(false);
        createErrorNotification(err);
      });
  };

  return (
    <div className="pb-6 flex flex-col min-h-screen overflow-hidden">
      {upcomingSubscription && (
        <div className={'alert alert-success text-white mt-6'}>
          <div className={'justify-start h-full mt-3 flex-grow-0'}>
            <BsInfoCircle size={17} />
          </div>
          <div className={'w-full flex-grow'}>
            <span className={'text-lg font-bold'}>
              Bis zum {currentPeriodEnd} beinhaltet dein Abonnement
            </span>
            <div className={'w-full'}>
              <div>
                -<span className={'ml-4 w-1/2 lg:w-1/6 inline-block'}>Basic</span>{' '}
                {currentPeriod.max_user}
              </div>
              <div>
                -<span className={'ml-4 w-1/2 lg:w-1/6 inline-block'}>Card Addon</span>{' '}
                {currentPeriod.card_addon ? 'Ja' : 'Nein'}
              </div>
              <div>
                -<span className={'ml-4 w-1/2 lg:w-1/6 inline-block'}>Project Addon</span>{' '}
                {currentPeriod.project_addon ? 'Ja' : 'Nein'}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="p-6">
        <h2 className="text-2xl font-bold text-primary">{t('commerce.licenses.manage_license')}</h2>

        <button
          className="btn btn-primary mt-2"
          onClick={() => redirectToPortal()}
          disabled={isRedirectingToPortal}
        >
          {t('commerce.licenses.manage_license')}{' '}
          {isRedirectingToPortal && <span className="loading loading-spinner" />}
        </button>
      </div>

      <div className="m-6">
        <LicenseSelection affiliateId={selectedAffiliate.id} />
      </div>
    </div>
  );
}
