import { useQuery } from '@tanstack/react-query';
import { PaymentService } from '@/services/PaymentService';

function usePrices() {
  const fetchPrices = async () => {
    return PaymentService.getPrices();
  };

  const { data: prices = {}, isLoading: pricesLoading } = useQuery({
    queryKey: ['prices'],
    queryFn: fetchPrices,
    staleTime: 1000 * 60 * 10, // 24 hours
    refetchOnMount: true,
  });

  return {
    prices,
    pricesLoading,
  };
}

export { usePrices };
