import { selectAffiliate } from '@/global/affiliateSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const AffiliateSwitch = () => {
  const affiliates = useSelector((state: any) => state.affiliate.affiliates);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);

  if (affiliates === null || Object.values(affiliates).length < 2 || selectAffiliate === null) {
    return <></>;
  }

  const dispatch = useDispatch();

  return (
    <div>
      <select
        className="select select-bordered w-full"
        defaultValue={selectedAffiliate.id}
        onChange={(e) => {
          dispatch(selectAffiliate(e.target.value));
          window.location.reload();
        }}
      >
        {Object.values(affiliates).map((affiliate: any) => (
          <option value={affiliate.id}>{affiliate.title}</option>
        ))}
      </select>
    </div>
  );
};
