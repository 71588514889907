import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { OnboardingLayout } from '@/components/Onboarding/OnboardingLayout';
import { CreateAffiliateForm } from '@/components/Form/Affiliate/CreateAffiliateForm';
import { createErrorNotification } from '@/helper/ApiHelper';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import { useOnboardingProvider } from '@/components/Context/OnboardingContext';

export default function Step_AffiliateData() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const affiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const { customer, refetchCustomer, updateCustomer, customerLoading } = useOnboardingProvider();

  useEffect(() => {
    dispatch(setStep(2));
    refetchCustomer();
  }, []);

  const nextStep = () => {
    navigate('/onboarding/select-license');
  };

  const previousStep = () => {
    navigate('/onboarding/get-started');
  };

  const submitForm = async (data) => {
    setIsSaving(true);

    const hasCustomerChanges = JSON.stringify(customer) !== JSON.stringify(data);
    if (!hasCustomerChanges) {
      nextStep();
      return;
    }

    if (affiliate.id) {
      data.id = affiliate.id;
    }

    try {
      await updateCustomer(data);
      nextStep();
    } catch (err) {
      setIsSaving(false);
      createErrorNotification(err);
    }
  };

  return (
    <OnboardingLayout>
      <div>
        <div className="mb-4">
          <h2 className="text-2xl font-bold text-primary">
            {t('onboarding.create_company.title')}
          </h2>
          <p className={'my-6'}>
            {t('onboarding.create_company.subtitle')}
            <br />
          </p>

          {customerLoading && <LoadingComponent paddingTop={false} />}

          {!customerLoading && (
            <CreateAffiliateForm
              submitForm={submitForm}
              defaultValues={customer}
              isLoading={isSaving}
              nextStep={nextStep}
              previousStep={previousStep}
            />
          )}
        </div>
      </div>
    </OnboardingLayout>
  );
}
