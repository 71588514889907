import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { PaymentService } from '@/services/PaymentService';
import { useLocation } from 'react-router-dom';

export type NUMBER_EMPLOYEE = 5 | 10 | 20 | 30 | 50;
export type LICENSE_TYPE =
  | 'workplan_team_license'
  | 'workplan_business_license'
  | 'workplan_enterprise_license';

function useCheckoutQuote() {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [numberEmployees, setNumberEmployees] = useState<NUMBER_EMPLOYEE>(10);
  const [licenseType, setLicenseType] = useState<LICENSE_TYPE>('workplan_business_license');
  const location = useLocation();

  const quoteKey = useMemo(
    () => ['quote', selectedAffiliate?.id, numberEmployees, licenseType],
    [selectedAffiliate?.id, numberEmployees, licenseType],
  );

  const fetchQuote = async ({ queryKey }) => {
    const [, affiliateId, number_employees, type] = queryKey;
    if (!affiliateId || !number_employees || !type) return [];
    return PaymentService.createQuote({
      affiliate_id: affiliateId,
      number_employees,
      license_type: type,
    });
  };

  const {
    data: quote = {},
    isLoading: quoteLoading,
    refetch: refetchQuote,
  } = useQuery({
    queryKey: quoteKey,
    queryFn: fetchQuote,
    enabled: location.pathname.includes('/checkout'),
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    refetchQuote,
    numberEmployees,
    setNumberEmployees,
    licenseType,
    setLicenseType,
    quote,
    quoteLoading,
  };
}

export { useCheckoutQuote };
