import React from 'react';
import logo from '@/assets/logo_wp.png';
import video from '@/assets/0010_workplan_eintragen.webm';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { Button } from '@/components/ui/button';
import { logoutUser } from '@/global/authSlice';
import { clearState as clearOnboardingState } from '@/global/onboardingSlice';
import { clearState as clearUserState } from '@/global/userSlice';
import { clearState as clearAffiliateState } from '@/global/affiliateSlice';
import { useDispatch } from 'react-redux';
import { AffiliateSwitch } from '../General/AffiliateSwitch';

export interface OnboardingLayoutProps {
  children: ReactNode;
  sidebar?: ReactNode;
  showSidebarOnMobile?: boolean;
}

export function OnboardingLayout({
  children,
  sidebar,
  showSidebarOnMobile = false,
}: OnboardingLayoutProps) {
  const { i18n } = useTranslation();
  const language = i18n.language || window.localStorage.i18nextLng;
  const dispatch = useDispatch();

  const changeLanguage = (evt) => {
    i18n.changeLanguage(evt.target.value);
  };

  const logout = () => {
    dispatch(logoutUser());
    dispatch(clearUserState());
    dispatch(clearOnboardingState());
    dispatch(clearAffiliateState());
  };

  function defaultSideBarContent() {
    return (
      <>
        <div className="flex flex-col space-y-4 text-left font-bold tracking-tight text-white text-5xl ml-4">
          <h2>{t('workplan.simple')}</h2>
          <h2>{t('workplan.time_tracking')}</h2>
          <h2>{t('workplan.with_workplan')}</h2>
        </div>
        <video className="mx-auto my-4" width={'80%'} autoPlay muted controls={false} loop>
          <source src={video} type="video/webm" />
        </video>
      </>
    );
  }

  return (
    <>
      <div className="w-full lg:flex mx-auto min-h-[100vh]">
        <div className="lg:w-[55%] flex flex-col">
          <div className="w-[85%] mx-auto py-3 flex justify-between items-center">
            <div className={'mt-3 flex flex-row space-x-3 items-center'}>
              <img src={logo} width={'50px'} />
              <AffiliateSwitch />
            </div>
            <div className={'mt-3 flex flex-row space-x-3 items-center'}>
              <select
                className={'select select-ghost select-sm '}
                value={language}
                onChange={changeLanguage}
              >
                <option value={'de'}>DE</option>
                <option value={'en'}>EN</option>
                <option value={'tr'}>TR</option>
              </select>
              <Button variant={'outline'} onClick={logout}>
                {t('menu.logout')}
              </Button>
            </div>
          </div>
          <div className="w-[85%] mx-auto flex items-center pt-10">{children}</div>
        </div>
        <div
          className={`${showSidebarOnMobile ? '' : 'hidden'} lg:flex lg:w-[45%] w-full items-center from-secondary bg-gradient-to-t to-primary py-12 mt-12 lg:mt-0 pb-12`}
        >
          <div className="mx-auto w-[90%] text-center">
            {sidebar ? sidebar : defaultSideBarContent()}
          </div>
        </div>
      </div>
    </>
  );
}
