import React from 'react';
import { createErrorNotification } from '@/helper/ApiHelper';
import { BsEye, BsFillEyeSlashFill, BsPlusLg } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import UpgradeSubscriptionBanner from '@/components/Commerce/UpgradeSubscriptionBanner';
import { TerminalService } from '@/services/TerminalService';
import { useTranslation } from 'react-i18next';
import { TerminalDownloadInfo } from '@/components/Terminal/TerminalDownloadInfo';

export default function TerminalSettingsPage() {
  const { t } = useTranslation();
  const [terminalSecret, setTerminalSecret] = useState(null);
  const [isSecretVisible, setIsSecretVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [hasLicense, setHasLicense] = useState(true);

  const createTerminalLicense = () => {
    setIsLoading(true);
    TerminalService.createTerminal(selectedAffiliate.id)
      .then((res: any) => {
        setTerminalSecret(res.terminal_secret);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => setIsLoading(false));
  };

  const loadTerminalLicense = () => {
    setIsLoading(true);
    TerminalService.getTerminal(selectedAffiliate.id)
      .then((res: any) => {
        setTerminalSecret(res.terminal_secret);
      })
      .catch((err) => {
        if (err.response?.data?.message !== 'PAYMENTPLAN_NOT_FOUND') {
          createErrorNotification(err);
        } else {
          setHasLicense(false);
          setTerminalSecret('schreib_uns_eine_email_wenn_du_das_ließt');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadTerminalLicense();
  }, []);

  return (
    <div className={'relative h-full'}>
      {!hasLicense && <UpgradeSubscriptionBanner addon={'terminal'} />}

      <div className={'p-6 ' + (!hasLicense ? 'blur-sm' : '')}>
        <div className="prose">
          <h3>{t('terminal.terminal')}</h3>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('terminal.terminal_license')}</span>
          </label>
          {isLoading ? (
            <LoadingComponent />
          ) : terminalSecret ? (
            <div className={'flex flex-row items-center'}>
              <input
                type={isSecretVisible ? 'text' : 'password'}
                placeholder=""
                value={terminalSecret}
                disabled={true}
                className="input input-bordered w-full"
              />
              &nbsp;
              {isSecretVisible ? (
                <BsFillEyeSlashFill
                  onClick={() => setIsSecretVisible(!isSecretVisible)}
                  size={25}
                  className={'ml-4 hover:cursor-pointer'}
                />
              ) : (
                <BsEye
                  onClick={() => setIsSecretVisible(!isSecretVisible)}
                  size={25}
                  className={'ml-4 hover:cursor-pointer'}
                />
              )}
            </div>
          ) : (
            <div
              className={'btn btn-primary text-white mt-3 hover:cursor-pointer'}
              onClick={createTerminalLicense}
            >
              {isLoading ? (
                <span className="loading loading-spinner mr-1"></span>
              ) : (
                <BsPlusLg size={17} className={'mr-1'} />
              )}
              {t('terminal.create')}
            </div>
          )}
        </div>

        <TerminalDownloadInfo />
      </div>
    </div>
  );
}
