import { createSlice } from '@reduxjs/toolkit';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    process: {
      currentStep: 1,
    },
    affiliate: {
      id: null,
      name: '',
      email: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        postal_code: '',
        state: 'None',
        country: 'Deutschland',
      },
    },
    subscription: {
      type: 'workplan_business_license',
      number_employees: 1,
    },
  },
  reducers: {
    setStep(state, action) {
      state.process.currentStep = action.payload;
    },
    setAffiliate(state, action) {
      state.affiliate = { ...state.affiliate, ...action.payload };
    },
    setSubscription(state, action) {
      state.subscription.type = action.payload.type;
      state.subscription.number_employees = action.payload.number_employees;
    },
    clearState(state) {
      state.subscription = {
        type: 'workplan_business_license',
        number_employees: 1,
      };

      state.affiliate = {
        id: null,
        name: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: 'None',
          country: '',
        },
      };

      state.process = {
        currentStep: 1,
      };
    },
  },
});

export const { setStep, setAffiliate, setSubscription, clearState } = onboardingSlice.actions;

export default onboardingSlice.reducer;
