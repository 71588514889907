import { apiRequest } from '@/helper/ApiHelper';

interface CheckoutParameter {
  affiliate_id: number;
  number_employees: number;
  license_type:
    | 'workplan_team_license'
    | 'workplan_business_license'
    | 'workplan_enterprise_license';
}

export const PaymentService = {
  createQuote: async (paramter: CheckoutParameter) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/quote',
        method: 'POST',
        data: paramter,
      })
        .then(resolve)
        .catch(reject);
    });
  },
  acceptQuote: async (quoteId: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/quote',
        method: 'PATCH',
        data: {
          quote_id: quoteId,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  createSubscription: async (parameter: CheckoutParameter) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/subscription',
        method: 'POST',
        data: parameter,
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getBusinessCustomer: async (id: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/customer/' + id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  createBusinessCustomer: async (affiliateData: any) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/customer',
        method: 'POST',
        data: {
          affiliate: affiliateData,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getSetupIntent: async (affiliateId: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/intent',
        method: 'POST',
        data: {
          affiliate_id: affiliateId,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getPrices: async () => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/prices',
        method: 'GET',
      })
        .then((res: any) => {
          let formattedPrices = {};
          Object.keys(res).forEach((priceId) => {
            formattedPrices[priceId] = {};

            if (res[priceId].tiers) {
              res[priceId].tiers?.forEach((tier, key) => {
                formattedPrices[priceId][key] = {
                  flat_amount: tier.flat_amount,
                  up_to: tier.up_to,
                };
              });
            } else {
              formattedPrices[priceId] = res[priceId];
            }
          });

          resolve(formattedPrices);
        })
        .catch(reject);
    });
  },
  getCurrentLicense: async (affiliateId) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/subscription/' + affiliateId,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  redirectToCheckout: (affiliateId, licenseType, numberEmployees) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment',
        method: 'POST',
        responseType: 'json',
        data: {
          affiliate_id: affiliateId,
          number_subscriptions: parseInt(numberEmployees),
          license_type: licenseType,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  redirectToPortal: (affiliateId: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/subscription/portal/' + affiliateId,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateSubscription: (affiliateId, licenseType, numberEmployees, instantUpgrade = false) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/subscription/' + affiliateId,
        method: 'PATCH',
        responseType: 'json',
        data: {
          number_employees: parseInt(numberEmployees),
          license_type: licenseType,
          instant_upgrade: instantUpgrade,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
