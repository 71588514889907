import './helper/Sentry';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import App from './App';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import '@/i18n/i18next';

import { store, persistor } from '@/global/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { themeChange } from 'theme-change';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './global/queryClient';

themeChange();

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          <ToastContainer containerId="workplan_toast" />
        </PersistGate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);
