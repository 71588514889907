import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import AppLayout from '@/components/Menu/AppLayout';
import Layout from '@/components/Layout';
import LogInPage from '@/pages/Auth/Login/LoginPage';
import RegisterPage from '@/pages/Auth/Register/RegisterPage';
import ValidateRegistrationPage from '@/pages/Auth/ValidateRegistration/ValidateRegistrationPage';
import DashboardPage from '@/pages/App/Affiliate/Dashboard/DasboardPage';
import ErrorPage from '@/pages/ErrorPage/ErrorPage';
import RequireAuthAndOnboarding from '@/pages/App/RequireAuthAndOnboarding';
import EmployeePage from '@/pages/App/Affiliate/Employee/EmployeePage';
import TimeOverview from '@/pages/App/Time/TimeOverview/TimeOverview';
import GeneralSettingsPage from '@/pages/App/Settings/General/GeneralSettingsPage';
import AbsenceOverview from '@/pages/App/Time/AbsenceOverview/AbsenceOverview';
import ProjectOverview from '@/pages/App/Time/ProjectOverview/ProjectOverview';
import CardSettingsPage from '@/pages/App/Settings/Card/CardSettingsPage';
import TerminalSettingsPage from '@/pages/App/Settings/Terminal/TerminalSettingsPage';
import CalendarPage from '@/pages/App/Time/Calendar/CalendarPage';
import PaymentSettingsPage from '@/pages/App/Settings/Payment/PaymentSettingsPage';
import ConfirmEmailPage from '@/pages/Auth/ConfirmEmail/ConfirmEmailPage';
import RequireOnboarding from '@/pages/Onboarding/RequireOnboarding';
import OnboardingLayout from '@/components/Menu/OnboardingLayout';
import OnboardingJoinAffiliate from '@/pages/Onboarding/Steps/JoinAffiliate';
import ResetPasswordPage from '@/pages/Auth/ResetPassword/ResetPasswordPage';
import NewPasswordPage from '@/pages/Auth/NewPassword/NewPasswordPage';
import ThanksPage from '@/pages/Thanks/ThanksPage';
import JoinAffiliate from '@/pages/Auth/JoinAffiliate/JoinAffiliatePage';
import RequireAuth from '@/pages/Auth/JoinAffiliate/RequireAuth';
import ExportPage from '@/pages/App/Time/Export/ExportPage';
import ProfilPage from '@/pages/App/Affiliate/Employee/Profile/ProfilePage';

import { wrapCreateBrowserRouter } from '@sentry/react';
import Health from '@/pages/Health';
import Step_AffiliateData from '@/pages/Onboarding/Steps/Step_AffiliateData';
import Step_LicenseSelection from '@/pages/Onboarding/Steps/Step_LicenseSelection';
import Step_CreateOrInvite from '@/pages/Onboarding/Steps/Step_CreateOrInvite';
import Step_Checkout from '@/pages/Onboarding/Steps/Step_Checkout';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <RequireAuthAndOnboarding />,
        children: [
          {
            path: '/',
            element: <AppLayout />,
            children: [
              { path: '/', element: <DashboardPage /> },
              {
                path: 'affiliate',
                children: [
                  { path: 'employees', element: <EmployeePage /> },
                  { path: 'employee/:userId', element: <ProfilPage /> },
                ],
              },
              {
                path: 'time',
                children: [
                  { path: 'overview', element: <TimeOverview /> },
                  { path: 'export', element: <ExportPage /> },
                  { path: 'calendar', element: <CalendarPage /> },
                  { path: 'absence', element: <AbsenceOverview /> },
                  { path: 'projects', element: <ProjectOverview /> },
                ],
              },
              {
                path: 'settings',
                children: [
                  { path: 'general', element: <GeneralSettingsPage /> },
                  { path: 'cards', element: <CardSettingsPage /> },
                  { path: 'terminal', element: <TerminalSettingsPage /> },
                  { path: 'license', element: <PaymentSettingsPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/',
        element: <RequireOnboarding />,
        children: [
          {
            path: 'onboarding',
            element: <OnboardingLayout />,
            children: [
              { path: 'get-started', element: <Step_CreateOrInvite /> },
              { path: 'create-affiliate', element: <Step_AffiliateData /> },
              { path: 'join-affiliate', element: <OnboardingJoinAffiliate /> },
              { path: 'select-license', element: <Step_LicenseSelection /> },
              { path: 'checkout', element: <Step_Checkout /> },
            ],
          },
        ],
      },
      {
        path: 'auth',
        children: [
          { path: 'login', element: <LogInPage /> },
          { path: 'register', element: <RegisterPage /> },
          { path: 'reset-password', element: <ResetPasswordPage /> },
          { path: 'new-password/:token', element: <NewPasswordPage /> },
          { path: 'confirm-email', element: <ConfirmEmailPage /> },
          { path: 'validation/:token', element: <ValidateRegistrationPage /> },
          {
            element: <RequireAuth />,
            children: [{ path: 'join-affiliate/:token', element: <JoinAffiliate /> }],
          },
        ],
      },
      {
        element: <OnboardingLayout />,
        children: [{ path: 'thanks', element: <ThanksPage /> }],
      },
      { path: '*', element: <ErrorPage /> },
    ],
  },
  {
    path: '/health',
    element: <Health />,
  },
]);
