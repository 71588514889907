import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStep } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { OnboardingLayout } from '@/components/Onboarding/OnboardingLayout';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '@/global/stripe';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { License } from '@/types/licensing/Licensing';
import { Button } from '@/components/ui/button';
import { useOnboardingProvider } from '@/components/Context/OnboardingContext';
import { CheckoutForm } from '@/components/Commerce/CheckoutForm';

export default function Step_Checkout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { quote, customer } = useOnboardingProvider();

  useEffect(() => {
    dispatch(setStep(2));
  }, []);

  const previousStep = () => {
    navigate('/onboarding/select-license');
  };

  return (
    <OnboardingLayout sidebar={sidebarContent()} showSidebarOnMobile>
      <div className="w-full">
        <div className="mb-6 flex justify-between items-center">
          <div>
            <h2 className="text-2xl text-primary font-bold">Warenkorb</h2>
            <span className="text-gray-700">Ihr Zahlungsmittel können Sie jederzeit ändern.</span>
          </div>
          <Button
            variant={'link'}
            onClick={() => {
              previousStep();
            }}
          >
            {t('general.back')}
          </Button>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>{t('onboarding.checkout.invoice_address')}</CardTitle>
          </CardHeader>
          <CardContent className="pb-4">
            {customer?.name && (
              <div className="flex flex-col">
                <span className="text-lg font-bold">{customer.name}</span>
                <span>{customer.email}</span>
                <span>{customer.address.line1}</span>
                <span>{customer.address.line2}</span>
                <span>
                  {customer.address.postal_code}, {customer.address.city}
                </span>
                <span>{customer.address.country}</span>
              </div>
            )}
          </CardContent>
          <CardFooter>
            <Button variant={'outline'} onClick={() => navigate('/onboarding/create-affiliate')}>
              {t('onboarding.checkout.change_address')}
            </Button>
          </CardFooter>
        </Card>

        {quote.client_secret && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: quote.client_secret,
            }}
          >
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </OnboardingLayout>
  );
}

function sidebarContent(): ReactNode {
  const { t } = useTranslation();
  const { prices, numberEmployees, licenseType, quote } = useOnboardingProvider();

  const licenseTitles: { [key in License]: string } = {
    [License.TEAM]: t('onboarding.choose_license.license_names.team'),
    [License.BUSINESS]: t('onboarding.choose_license.license_names.business'),
    [License.ENTERPRISE]: t('onboarding.choose_license.license_names.enterprise'),
  };

  const currentLicenseTitle = licenseTitles[licenseType] || '';

  return (
    <div className="text-white">
      <div className="bg-white w-full rounded-t-lg lg:rounded-lg shadow-md mt-8 text-gray-700 px-4 py-4">
        <div className="flex justify-between items-center ">
          <div className="text-left">
            <h2 className="text-2xl font-bold">{t('onboarding.checkout.pay_now')}</h2>
          </div>
          <div className="grid">
            <span className="text-2xl font-bold text-right">
              {quote.computed === undefined ? (
                <span className={'loading loading-sm loading-spinner'}></span>
              ) : (
                quote?.computed?.upfront?.amount_total / 100 + ',00'
              )}{' '}
              €
            </span>
            <span className="text-sm text-right text-gray-600">
              {t('onboarding.choose_license.configurator.single_time_payment')}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="text-left">
            <h2 className="text-lg font-bold">{currentLicenseTitle}</h2>
            <p>{t('onboarding.choose_license.configurator.trial')}</p>
          </div>
          <div className="grid">
            <span className="text-xl font-bold text-right">0,00 €</span>
          </div>
        </div>

        {licenseType === License.ENTERPRISE && (
          <>
            <div className="flex justify-between items-center mt-4">
              <div className="text-left">
                <h2 className="text-lg font-bold">
                  {t('onboarding.choose_license.configurator.card_reader')}
                </h2>
                <p>{t('onboarding.choose_license.configurator.card_reader_desc')}</p>
              </div>
              <div className="grid">
                <span className="text-xl font-bold text-right">
                  {prices['workplan_card_reader'].amount / 100 + ',00'} €
                </span>
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <div className="text-left">
                <h2 className="text-lg font-bold">
                  {t('onboarding.choose_license.configurator.cards')}
                </h2>
                <p>
                  {numberEmployees + ' ' + t('onboarding.choose_license.configurator.cards_desc')}
                </p>
              </div>
              <div className="grid">
                <span className="text-xl font-bold text-right">
                  {(numberEmployees * prices['workplan_card'].amount) / 100 + ',00'} €
                </span>
              </div>
            </div>
          </>
        )}

        <p className="text-left text-xs mt-4">
          <p>{t('onboarding.choose_license.configurator.after_trial')}</p>
        </p>
        <div className="flex justify-between items-center ">
          <div className="text-left">
            <h2 className="text-2xl font-bold">{currentLicenseTitle}</h2>
            <p>
              {numberEmployees}
              {' ' + t('onboarding.choose_license.configurator.employee')}
            </p>
          </div>
          <div className="grid">
            <span className="text-2xl font-bold text-right">
              {quote.computed === undefined ? (
                <span className={'loading loading-sm loading-spinner'}></span>
              ) : (
                quote?.computed?.recurring?.amount_total / 100 + ',00'
              )}{' '}
              €
            </span>
            <span className="text-sm text-right text-gray-600">
              {t('onboarding.choose_license.configurator.monthly_payment')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
