import React, { useEffect, useState } from 'react';

import { createErrorNotification, login } from '@/helper/ApiHelper';
import { AuthLayout } from '@/components/Auth/AuthLayout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAffiliate } from '@/global/onboardingSlice';

export default function LogInPage() {
  const { t } = useTranslation();
  const [mail, setMail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const from = location.state?.from?.pathname || '/';
  const needs_authentication = location.state?.needs_authentication ?? false;

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    login(mail, password)
      .then((res) => {
        setMail('');
        setPassword('');

        let affiliate = null as any;
        if (res.data.default_affiliate) {
          affiliate = res.data.affiliates[res.data.default_affiliate];
        }

        if (from.includes('join-affiliate') || affiliate?.has_license === true) {
          navigate(from, { replace: true });
        } else {
          dispatch(setAffiliate(affiliate));
          navigate('/onboarding/get-started');
        }
      })
      .catch((err: Error) => createErrorNotification(err))
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  }

  function handleChangeMail(event: React.ChangeEvent<HTMLInputElement>) {
    setMail(event.target.value.toLowerCase());
  }

  function handleChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  useEffect(() => {
    if (password !== '' && mail !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [mail, password]);

  return (
    <AuthLayout>
      <div className="w-[85%] max-w-[400px] mx-auto">
        {needs_authentication && (
          <div role="alert" className="alert mb-3">
            <BsExclamationCircle size={15} />
            <span>{t('auth.please_login')}</span>
          </div>
        )}

        <h2 className="text-2xl font-semibold py-2">{t('auth.login')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">{t('auth.email')}</span>
            </label>
            <input
              type="email"
              placeholder={t('auth.your_email')}
              className="input input-bordered w-full"
              onChange={handleChangeMail}
              value={mail}
              tabIndex={1}
            />
          </div>
          <div className="form-control w-full mt-2">
            <label className="label">
              <span className="label-text">{t('auth.password')}</span>
              <span className="label-text-alt">
                <Link to={'/auth/reset-password'} className="hover:underline" tabIndex={4}>
                  {t('auth.password_reset.forgot')}
                </Link>
              </span>
            </label>
            <input
              type="password"
              placeholder={t('auth.your_password')}
              className="input input-bordered w-full"
              onChange={handleChangePassword}
              value={password}
              tabIndex={2}
            />
          </div>
          <div className="justify-end mt-4">
            <button
              type="submit"
              className="btn btn-primary w-full text-white disabled:text-white"
              disabled={isDisabled || isLoading}
              tabIndex={3}
            >
              {isLoading && <span className={'loading loading-spinner'}></span>} {t('auth.login')}
            </button>
          </div>
        </form>
        <div className="mt-8 text-sm">
          <p>
            {t('auth.dont_have_an_account')}{' '}
            <Link to={'/auth/register'} className="hover:underline">
              {' '}
              {t('auth.register_cta')}
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
