import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { apiRequest, createErrorNotification } from '@/helper/ApiHelper';
import { BsCheckSquare, BsInfoCircle, BsPencilFill, BsTrash, BsXSquare } from 'react-icons/bs';
import EditShiftModal from '@/components/Modal/EditShiftModal';
import { DeleteShiftModal } from '@/components/Modal/DeleteShiftModal';
import { AbsenceService } from '@/services/AbsenceService';
import { ShiftService } from '@/services/ShiftService';
import { useTranslation } from 'react-i18next';

export const SlotListModal = ({
  isModalOpen,
  setIsModalOpen,
  userId = null,
  reload = () => {},
  projectId = null,
  start,
  end,
  cardId = null,
}) => {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [user, setUser] = useState({}) as any;
  const [shifts, setShifts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditShiftModalOpen, setIsEditShiftModalOpen] = useState(false);
  const [isDeleteShiftModalOpen, setIsDeleteShiftModalOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null) as any;
  const { t } = useTranslation();

  useEffect(() => {
    if (isModalOpen === true) {
      setUser({});
      setShifts([]);
      getShifts();
    }
  }, [isModalOpen]);

  const onUpdate = () => {
    getShifts();
    reload();
  };

  const getShifts = () => {
    setIsLoading(true);
    ShiftService.getPeriod(
      start?.toISO({ suppressMilliseconds: true }),
      end.toISO({ suppressMilliseconds: true }),
      selectedAffiliate.id,
      userId ?? null,
      cardId ?? null,
      projectId ?? null,
    )
      .then((res: any) => {
        setShifts(res);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteShift = (shift) => {
    setSelectedShift({
      id: shift.id,
      is_absence: shift.is_absence,
      name: shift.name,
      date: shift.start,
    });
    setIsDeleteShiftModalOpen(true);
  };

  const validateAbsence = (absenceId, userId, status) => {
    setIsSaving(true);

    AbsenceService.validateAbsence(absenceId, status)
      .then(() => {
        reload();
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const editShift = (shift) => {
    setSelectedShift({
      id: shift.id,
    });
    setIsEditShiftModalOpen(true);
  };

  let formattedStart = DateTime.fromISO(start)?.toFormat('dd.LL.');
  let formattedEnd = DateTime.fromISO(end)?.toFormat('dd.LL.');

  let title = t('time_tracking.working_time');
  if (user.first_name !== null && user.first_name !== undefined) {
    title = user?.first_name + ' ' + user?.last_name;
  }

  if (start !== null) {
    title = title + ': ' + formattedStart;
  }

  if (end !== null && formattedStart !== formattedEnd) {
    title = title + ' - ' + formattedEnd;
  }

  return (
    <>
      <Modal
        modalName="show_shift_details"
        headline={title}
        isOpen={isModalOpen}
        disableSafe={false}
        onSafe={() => {}}
        primaryButtonClass={'hidden'}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="space-y-1">
          <div className="join join-vertical w-full">
            {isLoading && (
              <div className={'flex justify-center py-6'}>
                <span className={'loading loading-lg loading-spinner'}></span>
              </div>
            )}

            {!isLoading && shifts.length === 0 && (
              <div>
                <div className={'alert alert text-white mt-6'}>
                  <div className={'justify-start h-full mt-3 flex-grow-0'}>
                    <BsInfoCircle size={17} className={'text-black'} />
                  </div>
                  <div className={'text-black'}>{t('time_tracking.modals.shift_list_message')}</div>
                </div>
              </div>
            )}

            {!isLoading &&
              shifts?.flat().map((shift: any, key) => {
                const isAbsence = shift.is_absence ?? false;

                return (
                  <div key={key} className={'collapse join-item border border-base-300'}>
                    <input
                      type="radio"
                      name="show_shift_details_modal"
                      defaultChecked={key === 0}
                    />

                    <div className="collapse-title text-xl font-medium">
                      {DateTime.fromISO(shift.start).toFormat('dd.LL.')}
                    </div>

                    <div className="collapse-content space-y-3">
                      {isAbsence === false && (
                        <div className={'grid grid-cols-3'}>
                          <div>
                            <span className="text-xs">{t('general.name')}</span>
                            <br />
                            <span className="font-bold">{shift.name}</span>
                          </div>
                          <div className="text-center self-end"></div>
                          <div>
                            <span className="text-xs">{t('general.project')}</span>
                            <br />
                            <span className="font-bold">{shift?.project?.title ?? '-'}</span>
                          </div>
                        </div>
                      )}

                      {isAbsence && (
                        <div className={'grid grid-cols-3'}>
                          <div>
                            <span className="text-xs">{t('general.name')}</span>
                            <br />
                            <span className="font-bold">{shift.name}</span>
                          </div>
                          <div className="text-center self-end"></div>
                          <div>
                            <span className="text-xs">{t('general.confirmed')}</span>
                            <br />
                            <span className="font-bold">
                              {t('general.' + (shift.is_accepted ? 'yes' : 'no'))}
                            </span>
                          </div>
                        </div>
                      )}

                      {isAbsence && (
                        <div className={'grid grid-cols-3 mb-4'}>
                          <div>
                            <span className="text-xs">{t('time_tracking.absence.type')}</span>
                            <br />
                            <span className="font-bold">
                              {t('time_tracking.absence.' + shift.type)}
                            </span>
                          </div>
                          <div className="text-center self-end"></div>
                          <div>
                            <span className="text-xs">{t('time_tracking.absence.duration')}</span>
                            <br />
                            <span className="font-bold">{Math.round(shift.duration)} h</span>
                          </div>
                        </div>
                      )}

                      {isAbsence === false && (
                        <div className={'grid grid-cols-3'}>
                          <div>
                            <span className="text-xs">
                              {DateTime.fromISO(shift.start).toFormat('dd.LL.y')}
                            </span>
                            <br />
                            <span className="font-bold">
                              {DateTime.fromISO(shift.start).toFormat('HH:mm')}
                            </span>
                          </div>
                          <div className="text-center self-end">{t('time_tracking.to')}</div>
                          <div>
                            <span className="text-xs">
                              {DateTime.fromISO(shift.end).toFormat('dd.LL.y')}
                            </span>
                            <br />
                            <span className="font-bold">
                              {DateTime.fromISO(shift.end).toFormat('HH:mm')}
                            </span>
                          </div>
                        </div>
                      )}

                      {isAbsence === false && (
                        <div className={'grid grid-cols-3'}>
                          <div>
                            <span className="text-xs">{t('time_tracking.duration')}</span>
                            <br />
                            <span className="font-bold">{shift.duration} h</span>
                          </div>
                          <div className="text-center self-end"></div>
                          <div>
                            <span className="text-xs">{t('time_tracking.pause')}</span>
                            <br />
                            <span className="font-bold">{shift.pause} min</span>
                          </div>
                        </div>
                      )}

                      {isAbsence === false && (
                        <div className={'grid'}>
                          <div>
                            <span className="text-xs">{t('general.note')}</span>
                            <br />
                            <span className="font-bold">
                              {shift.note === '' ? '-' : shift.note}
                            </span>
                          </div>
                        </div>
                      )}

                      {isAbsence && (
                        <div className={'flex justify-end space-x-3'}>
                          <div
                            className={'btn btn-success'}
                            onClick={() => validateAbsence(shift.id, shift.user_id, 'accepted')}
                          >
                            {isSaving ? (
                              <span className={'loading-md loading-spinner'}></span>
                            ) : (
                              <BsCheckSquare size={17} className={'rounded-none'} />
                            )}{' '}
                            {t('general.accept')}
                          </div>
                          <div
                            className={'btn btn-ghost bg-base-200'}
                            onClick={() => validateAbsence(shift.id, shift.user_id, 'declined')}
                          >
                            {isSaving ? (
                              <span className={'loading-md loading-spinner'}></span>
                            ) : (
                              <BsXSquare size={17} className={'rounded-none'} />
                            )}{' '}
                            {t('general.decline')}
                          </div>
                        </div>
                      )}

                      {isAbsence === false && (
                        <div className={'flex justify-end space-x-3'}>
                          <button
                            className={'btn btn-ghost bg-base-200'}
                            onClick={() => editShift(shift)}
                          >
                            {isSaving ? (
                              <span className={'loading loading-spinner'}></span>
                            ) : (
                              <BsPencilFill size={17} />
                            )}{' '}
                            {t('general.edit')}
                          </button>
                          <button
                            className={'btn btn-error'}
                            disabled={selectedAffiliate?.isAdmin === false}
                            onClick={() => deleteShift(shift)}
                          >
                            {isSaving ? (
                              <span className={'loading loading-spinner'}></span>
                            ) : (
                              <BsTrash size={17} />
                            )}{' '}
                            {t('general.delete')}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
      <EditShiftModal
        reload={onUpdate}
        isModalOpen={isEditShiftModalOpen}
        setIsModalOpen={setIsEditShiftModalOpen}
        shiftId={selectedShift?.id}
      />
      <DeleteShiftModal
        reload={onUpdate}
        isAbsence={selectedShift?.is_absence}
        isModalOpen={isDeleteShiftModalOpen}
        setIsModalOpen={setIsDeleteShiftModalOpen}
        shiftId={selectedShift?.id}
        date={DateTime.fromISO(selectedShift?.date).toFormat('dd.LL.')}
        userName={selectedShift?.name}
      />
    </>
  );
};
