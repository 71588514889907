import React from 'react';
import { createContext, useContext } from 'react';
import { LICENSE_TYPE, NUMBER_EMPLOYEE, useCheckoutQuote } from '@/api/hooks/useCheckoutQuote';
import { usePrices } from '@/api/hooks/usePrices';
import { useBusinessCustomer } from '@/api/hooks/useBusinessCustomer';

interface OnboardingContext {
  numberEmployees: NUMBER_EMPLOYEE;
  setNumberEmployees: (number: NUMBER_EMPLOYEE) => void;
  licenseType: LICENSE_TYPE;
  setLicenseType: (string: LICENSE_TYPE) => void;
  quote: any;
  quoteLoading: boolean;
  refetchQuote: () => void;
  prices: any;
  pricesLoading: boolean;
  customer: any;
  customerLoading: boolean;
  refetchCustomer: () => void;
  updateCustomer: (data: any, options?: any) => Promise<any>;
}

const OnboardingContext = createContext<OnboardingContext | undefined>(undefined);

function OnboardingProvider({ children }) {
  const {
    numberEmployees,
    setNumberEmployees,
    licenseType,
    setLicenseType,
    quote,
    quoteLoading,
    refetchQuote,
  } = useCheckoutQuote();
  const { prices, pricesLoading } = usePrices();
  const { customer, refetchCustomer, updateCustomer, customerLoading } = useBusinessCustomer();

  const value: OnboardingContext = {
    customer,
    refetchCustomer,
    updateCustomer,
    customerLoading,
    numberEmployees,
    setNumberEmployees,
    licenseType,
    setLicenseType,
    quote,
    quoteLoading,
    refetchQuote,
    prices,
    pricesLoading,
  };

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}

function useOnboardingProvider() {
  const context = useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error('useEmployeesPageContext must be used within an EmployeesPageProvider');
  }

  return context;
}

export { OnboardingProvider, useOnboardingProvider };
