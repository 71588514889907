import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PaymentService } from '@/services/PaymentService';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAffiliate, setAffiliates } from '@/global/affiliateSlice';

function useBusinessCustomer() {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const affiliates = useSelector((state: any) => state.affiliate.affiliates);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const customerKey = useMemo(
    () => ['business_customer', selectedAffiliate.id],
    [selectedAffiliate.id],
  );

  const fetchCustomer = async ({ queryKey }) => {
    const [, affiliateId] = queryKey;
    if (!affiliateId) return [];
    return PaymentService.getBusinessCustomer(affiliateId);
  };

  const {
    data: customer = {},
    refetch: refetchCustomer,
    isLoading: customerLoading,
  } = useQuery({
    queryKey: customerKey,
    queryFn: fetchCustomer,
    enabled: true,
  });

  const { mutateAsync: updateCustomer } = useMutation({
    mutationFn: (data) => PaymentService.createBusinessCustomer(data),
    mutationKey: ['business_customer', selectedAffiliate.id],
    onSuccess: (res: any) => {
      queryClient.setQueryData(['business_customer', res.id], res);
      let newAffiliates: any = { ...affiliates };
      if (false === Object.keys(newAffiliates).includes(res.id)) {
        newAffiliates[res.id] = {
          id: res.id,
          title: res.name,
          isAdmin: true,
          isConfigured: false,
          hasLicense: false,
          addons: {
            projects: false,
            cards: false,
          },
        };

        dispatch(setAffiliates(newAffiliates));
        dispatch(selectAffiliate(res.id));
      }
    },
  });

  return {
    customer,
    refetchCustomer,
    updateCustomer,
    customerLoading,
  };
}

export { useBusinessCustomer };
