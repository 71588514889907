import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useBlogPosts() {
  return useQuery({
    queryKey: ['posts'],
    queryFn: async () => {
      const response = await axios.get('https://workplan.digital/wp-json/wp/v2/posts?_embed');
      return response.data;
    },
    enabled: true,
  });
}
