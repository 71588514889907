import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useTranslation } from 'react-i18next';

export function CreateAffiliateForm({
  submitForm,
  defaultValues,
  isLoading,
  previousStep,
  nextStep,
}) {
  // 1. Define your form.
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z
      .string()
      .min(2, {
        message: t('onboarding.create_company.title_error'),
      })
      .trim()
      .nonempty(),
    email: z
      .string()
      .email({ message: t('onboarding.create_company.email_error') })
      .trim()
      .nonempty(),
    address: z.object({
      line1: z.string().trim().nonempty(),
      line2: z.string().nullable().optional(),
      city: z.string().trim().nonempty(),
      state: z.string().trim().length(5),
      postal_code: z.string().trim().nonempty(),
      country: z.string().trim().nonempty(),
    }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: defaultValues?.name,
      email: defaultValues?.email,
      address: {
        line1: defaultValues?.address?.line1,
        line2: defaultValues?.address?.line2,
        city: defaultValues?.address?.city,
        postal_code: defaultValues?.address?.postal_code,
        state: defaultValues?.address?.state,
        country: 'Deutschland',
      },
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitForm)} className="space-y-3">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('onboarding.create_company.company_name')}</FormLabel>
              <FormControl>
                <Input placeholder="Muster GmbH" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('onboarding.create_company.company_email')}</FormLabel>
              <FormControl>
                <Input placeholder="invoice@muster-gmbh.de" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address.line1"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('onboarding.create_company.company_street')}</FormLabel>
              <FormControl>
                <Input placeholder="Hauptstraße 1" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address.line2"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('onboarding.create_company.company_extra')}</FormLabel>
              <FormControl>
                <Input placeholder="Stockwerk, Tür, ..." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address.city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('onboarding.create_company.company_city')}</FormLabel>
              <FormControl>
                <Input placeholder="Hamburg" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 space-x-2">
          <FormField
            control={form.control}
            name="address.state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('onboarding.create_company.company_state')}</FormLabel>
                <FormControl>
                  <Select name={'state'} onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="None" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="None">
                        {t('general_settings.country.germany.state.none')} (
                        {t('general.no_holidays')})
                      </SelectItem>
                      <SelectItem value="DE-BW">
                        {t('general_settings.country.germany.state.de-bw')}
                      </SelectItem>
                      <SelectItem value="DE-BY">
                        {t('general_settings.country.germany.state.de-by')}
                      </SelectItem>
                      <SelectItem value="DE-BE">
                        {t('general_settings.country.germany.state.de-be')}
                      </SelectItem>
                      <SelectItem value="DE-BB">
                        {t('general_settings.country.germany.state.de-bb')}
                      </SelectItem>
                      <SelectItem value="DE-HB">
                        {t('general_settings.country.germany.state.de-hb')}
                      </SelectItem>
                      <SelectItem value="DE-HH">
                        {t('general_settings.country.germany.state.de-hh')}
                      </SelectItem>
                      <SelectItem value="DE-HE">
                        {t('general_settings.country.germany.state.de-he')}
                      </SelectItem>
                      <SelectItem value="DE-NI">
                        {t('general_settings.country.germany.state.de-ni')}
                      </SelectItem>
                      <SelectItem value="DE-MV">
                        {t('general_settings.country.germany.state.de-mv')}
                      </SelectItem>
                      <SelectItem value="DE-NW">
                        {t('general_settings.country.germany.state.de-nw')}
                      </SelectItem>
                      <SelectItem value="DE-RP">
                        {t('general_settings.country.germany.state.de-rp')}
                      </SelectItem>
                      <SelectItem value="DE-SL">
                        {t('general_settings.country.germany.state.de-sl')}
                      </SelectItem>
                      <SelectItem value="DE-SN">
                        {t('general_settings.country.germany.state.de-sn')}
                      </SelectItem>
                      <SelectItem value="DE-ST">
                        {t('general_settings.country.germany.state.de-st')}
                      </SelectItem>
                      <SelectItem value="DE-SH">
                        {t('general_settings.country.germany.state.de-sh')}
                      </SelectItem>
                      <SelectItem value="DE-TH">
                        {t('general_settings.country.germany.state.de-th')}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address.postal_code"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('onboarding.create_company.company_zip')}</FormLabel>
                <FormControl>
                  <Input placeholder="12345" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          disabled={true}
          name="address.country"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('onboarding.create_company.company_country')}</FormLabel>
              <FormControl>
                <Input placeholder="Deutschland" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="join grid grid-cols-2 w-full sm:w-96 mx-auto px-6">
          <button className="join-item btn btn-outline" onClick={() => previousStep()}>
            {t('general.back')}
          </button>

          {isLoading ? (
            <div className={'btn btn-disabled'}>
              <span className={'loading loading-spinner'} /> {t('general.further')}
            </div>
          ) : (
            <button type="submit" className={'join-item btn btn-outline '}>
              {t('general.further')}
            </button>
          )}
        </div>
      </form>
    </Form>
  );
}
