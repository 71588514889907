import { t } from 'i18next';
import React from 'react';

export const TerminalDownloadInfo = () => {
  const downloadUrl = 'https://github.com/Workplan-Digital/Terminal/releases/latest';
  const documentationUrl = 'https://docs.workplan.digital/terminal/installation';

  return (
    <div className="mt-10 max-w-[700px] hidden md:block">
      <div className="text-xl font-bold">{t('terminal.download_terminal')}</div>
      <div className="flex space-between justify-between justify-items-center">
        <p className="mt-1">
          {t('terminal.download_now')}
          <br />
          {t('terminal.download_documentation')}{' '}
          <a className="text-primary" target="_blank" href={documentationUrl}>
            {t('terminal.download_documentation_link')}
          </a>
          .
        </p>

        <a
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
          href={downloadUrl}
          target="_blank"
        >
          {t('terminal.download')}
        </a>
      </div>
    </div>
  );
};
